/** @jsx jsx */
import { jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, useThemeProps } from "@wsui/base";

const SearchFieldButtonRoot = styled(Button)`
  display: inline-flex;
  place-content: center;
  font-size: 19px;
  width: 108px;
`;

export default function SearchFieldButton(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "SearchFieldButton" });
  let { label, ...restProps } = props;
  return (
    <SearchFieldButtonRoot aria-label={label} {...restProps}>
      {label}
    </SearchFieldButtonRoot>
  );
}
