/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { generateUniqueId, withDefaultProps } from "@wsui/base";
import { FormInputSubmitField } from "@wsui/form";
import { Formik, Form } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchBox } from "react-instantsearch-hooks";

import SearchFieldButton from "../../SearchFieldButton.jsx";

export default function SearchBox(props) {
  let {
    id,
    label,
    submitLabel,
    placeholder,
    // eslint-disable-next-line no-unused-vars
    searchAsYouType,
    name = "query",
    ...restProps
  } = props;
  const { t } = useTranslation();
  const {
    query,
    refine,
    clear,
    // isSearchStalled,
  } = useSearchBox(props);

  const [defaultId] = useState(() => `search-box-${generateUniqueId()}`);

  label = label || t(["wsui.algolia.searchBoxLabel"]);
  submitLabel = submitLabel || t(["wsui.algolia.searchBoxSubmitLabel"]);
  id = id || defaultId;

  return (
    <Formik
      initialValues={{ [name]: query || "" }}
      onSubmit={async ({ [name]: query }) => {
        refine(query);
      }}
      onReset={async () => {
        clear();
      }}
    >
      {() => (
        <Form
          css={css`
            position: relative;
          `}
        >
          <FormInputSubmitField
            label={label}
            hideLabel
            id={id}
            name={name}
            placeholder={
              placeholder || t(["wsui.algolia.searchBoxPlaceholder"])
            }
            inputProps={{
              style: css`
                background-image: url(/icons/search.svg);
                background-position: 26px 10px;
                background-repeat: no-repeat;
                background-size: 2rem;
                padding-left: 4.5rem;
                border-radius: 10px;

                @media (max-width: 640px) {
                  background-position: 12px 8px;
                  background-size: 1.8rem;
                  padding-left: 3rem;
                }
              `,
            }}
            components={{
              InputWrapper: {
                style: {
                  fontSize: [4, 5.2],
                  fontFamily: "Calibri",
                },
              },
              SubmitButton: withDefaultProps(SearchFieldButton, {
                label: submitLabel,
                color: "yellow",
                style: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "CalibriBold",
                },
              }),
            }}
            {...restProps}
          />
        </Form>
      )}
    </Formik>
  );
}
