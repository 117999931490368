/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import Seo from "@whitespace/gatsby-theme-wordpress-basic/src/components/SEO";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import SearchProvider from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/algolia/SearchProvider.jsx";
import {
  // SearchBox,
  // Hits,
  Pagination as DefaultPagination,
  // MenuButtonGroup,
} from "@wsui/algolia";
import {
  Section,
  PageGrid,
  PageGridItem,
  useThemeProps,
  PageSection,
  Heading,
  H,
  handleComponentsProp,
} from "@wsui/base";
import { useTranslation } from "react-i18next";
import { Configure } from "react-instantsearch-hooks";

import Hits from "../../../../wsui/components/Hits.jsx";
import SearchBox from "../../../../wsui/components/SearchBox.jsx";

// import { useTranslation } from "react-i18next";
// import SiteSearch from "../components/algolia/SiteSearch.jsx";

export default function SearchTemplate(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "SearchTemplate" });
  props = useThemeProps({ props, name: "Template" });
  let { defaultColspan = 7, footerMargin = null } = props;
  const { title } = usePageContext();
  const { t, i18n } = useTranslation();
  let { components, ...restProps } = props;
  let { Pagination } = handleComponentsProp(components, {
    Pagination: DefaultPagination,
  });

  return (
    <div
      css={css`
        padding-bottom: ${footerMargin && theme.getLength(footerMargin)};
      `}
    >
      <SearchProvider routing={true}>
        <Seo title={title} />
        <PageSection backgroundColor={theme.getColor("paxxo")}>
          <PageGrid>
            <PageGridItem colspan={defaultColspan}>
              <Heading
                marginAfter
                css={css`
                  display: flex;
                  justify-content: center;
                  color: white;
                `}
              >
                {t(["search.searchTitle"])}
              </Heading>
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1fr;
                  gap: 1rem;
                `}
                {...restProps}
              >
                <Configure filters={`language:${i18n.language}`} />
                <SearchBox searchAsYouType={false} />
              </div>
            </PageGridItem>
          </PageGrid>
        </PageSection>
        <PageSection backgroundColor={theme.getColor("blue.light")}>
          <PageGrid maxWidth="1650px">
            <div id="search-results">
              <H
                css={css`
                  ${theme.styleUtils.visuallyHidden}
                `}
              >
                {t("wsui.algolia.searchResultsHeading")}
              </H>
              <Section>
                <Hits searchPageType="search" />
              </Section>
            </div>
            <Pagination />
          </PageGrid>
        </PageSection>
      </SearchProvider>
    </div>
  );
}
