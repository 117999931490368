/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  HitsController,
  HitController as DefaultHitComponent,
} from "@wsui/algolia";
import { Alert, handleComponentsProp, useThemeProps } from "@wsui/base";
import { Children } from "react";
import { useTranslation } from "react-i18next";
import { useHits, useInstantSearch } from "react-instantsearch-hooks";

// import DefaultHitComponent from "./HitController.jsx";
// import { useTranslation } from "react-i18next";

function RenderIfChildren({ as: Component = "div", children, ...restProps }) {
  if (!Children.count(children)) {
    return null;
  }
  return <Component {...restProps}>{children}</Component>;
}

const DefaultEmptyResultsWrapper = styled(RenderIfChildren)``;

const DefaultEmptyRequestWrapper = styled(RenderIfChildren)``;

export default function Hits(props) {
  const { t } = useTranslation();
  props = useThemeProps({ props, name: "AlgoliaHits" });
  let {
    hits,
    // results,
    // sendEvent,
  } = useHits(props);

  const title = <div>{t(`wsui.algolia.emptyResultsMessage`)}.</div>;

  const description = (
    <div>
      <h4
        css={css`
          margin-bottom: 0;
        `}
      >
        {t(`wsui.algolia.emptyResultsSuggestions.title`)}:
      </h4>
      <ul>
        <li>{t(`wsui.algolia.emptyResultsSuggestions.list.checkSpelling`)}</li>
        <li>
          {t(`wsui.algolia.emptyResultsSuggestions.list.lessSpecificWords`)}
        </li>
        <li>{t(`wsui.algolia.emptyResultsSuggestions.list.tryAlternative`)}</li>
      </ul>
    </div>
  );
  const {
    hitComponent = DefaultHitComponent,
    children,
    emptyRequestMessage,
    emptyResultsMessage = (
      <Alert severity="info" description={description} title={title} />
    ),
    components,
    ...restProps
  } = props;
  let { EmptyResultsWrapper, EmptyRequestWrapper } = handleComponentsProp(
    components,
    {
      EmptyResultsWrapper: DefaultEmptyResultsWrapper,
      EmptyRequestWrapper: DefaultEmptyRequestWrapper,
    },
  );
  // const { t } = useTranslation();

  // if (typeof children === "function") {
  //   return children({
  //     hits,
  //     results,
  //     sendEvent,
  //     hitComponent: Hit,
  //     ...restProps,
  //   });
  // }
  const { results } = useInstantSearch();

  if (children) {
    throw new Error(
      "Children not supported for Hits component. Use hitComponent prop instead.",
    );
  }

  if (!results || results.__isArtificial) {
    return null;
  }

  if (!hits?.length) {
    if (results.intercepted) {
      return (
        <EmptyRequestWrapper {...restProps}>
          {emptyRequestMessage}
        </EmptyRequestWrapper>
      );
    }
    return (
      <EmptyResultsWrapper {...restProps}>
        {emptyResultsMessage}
      </EmptyResultsWrapper>
    );
  }

  return (
    <HitsController hitComponent={hitComponent} hits={hits} {...restProps} />
  );
}
